<template>
  <div class="page-main-box" id="serviceOrderBox">
    <!-- 有数据 -->
    <div class="pt-24 pl-16 pr-16">
      <!-- 搜索头 -->
      <search-header :schemaData="schema" @getDataList="handleFormSearch" />

      <!-- 列表 -->
      <div :style="{ height: tableHeight + 'px' }">
        <p-table
          id="service-order-table"
          :dataSource="tableDataSource"
          height="auto"
          @sort-change="sortChangeEvent"
        >
          <p-t-column type="seq" width="60" title="序号" fixed="left" />
          <p-t-column field="company_name" min-width="336" title="企业名称">
            <template v-slot="{ row }">
              <span>{{
                row.company_name?.length > 18
                  ? row.company_name?.substr(0, 18) + "..."
                  : row.company_name
              }}</span>
            </template>
          </p-t-column>
          <p-t-column field="plate_number" min-width="120" title="车牌号" />
          <p-t-column
            field="plate_color_name"
            min-width="141"
            title="车牌颜色"
          />
          <p-t-column field="status_name" min-width="250" title="备案情况" />
          <p-t-column
            field="update_time"
            min-width="209"
            title="更新时间"
            sortable
          >
            <template v-slot="{ row }">
              <span>{{
                formatToDateTime(row.update_time, "yyyy-MM-dd hh:mm:ss")
              }}</span>
            </template>
          </p-t-column>
        </p-table>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive, toRaw, onMounted } from "vue";
import { Table } from "@wlhy-web-components/common";
import { searchHeaderOptions } from "./Constants";
import { useEtcVehiclePageApi } from "@/apis/etc";
import { useCompanyEnumApi } from "@/apis/enums";
import useSearchForm from "@/hooks/UseSearchForm";
import $await from "@/utils/await";
import useTableDataRecords from "@/hooks/useTableDataRecords";
import useTableHeight from "@/hooks/useTableHeight";
import { formatToDateTime } from "@wlhy-web-lib/shared";
import useDebounce from "@/hooks/useDebounce";
import useSearch from "@/hooks/useSearch";

export default {
  name: "service-order",
  components: {},
  setup() {
    const etcVehiclePageApi = useEtcVehiclePageApi();
    const companyEnumApi = useCompanyEnumApi();

    const { filter, handleFormSearch } = useSearchForm(searchHeaderOptions);

    // table高度
    const tableHeight = ref();

    // 传入弹窗数据
    const childRow = ref({});

    // 弹窗控制
    const visible = ref(false);

    const currentStepComponent = ref();

    // 列表数据
    const tableDataSource = reactive({
      loading: false,
      data: [],
      error: "",
      meta: {
        total: 0,
        pageSize: 20,
        currentPageNo: 1
      },
      sum: {
        data: [],
        loading: false,
        error: ""
      }
    });

    // 获取列表数据
    const getList = Table.useTableConfig({
      filter,
      dataSource: tableDataSource,
      async fetchData() {
        const data = {
          ...toRaw(filter.value),
          size: tableDataSource.meta.pageSize,
          page: tableDataSource.meta.currentPageNo
        };
        const [, res] = await $await(etcVehiclePageApi(data));
        return useTableDataRecords(res);
      }
    });

    // 获取公司枚举列
    const handleSearch = useDebounce(async value => {
      const [, res] = await $await(
        companyEnumApi({ name: value, page: 1, size: 100 })
      );
      useSearch(searchHeaderOptions, res, "company_id", false);
    }, 500);

    handleSearch();

    const sortChangeEvent = colums => {
      const { order } = colums;
      filter.value.sort = "update_time " + order;
      getList();
    };

    onMounted(() => {
      tableHeight.value = useTableHeight();
    });

    return {
      getList,
      currentStepComponent,
      tableHeight,
      childRow,
      handleFormSearch,
      visible,
      tableDataSource,
      schema: searchHeaderOptions,
      formatToDateTime,
      sortChangeEvent
    };
  }
};
</script>

<style lang="less" scoped>
#serviceOrderBox {
  position: relative;
  overflow: hidden;
}
</style>
