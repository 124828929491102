/**
 * 搜索头
 */
export const searchHeaderOptions = [
  {
    type: "select",
    field: "status",
    label: "备案情况",
    iw: 160,
    defaultValue: null,
    options: [
      { value: "RECORD", label: "成功" },
      { value: "NO_RECORD", label: "失败" }
    ]
  },
  {
    field: "company_id",
    label: "企业名称",
    type: "select-data-search",
    defaultValue: null,
    iw: 160,
    props: {
      placeholder: "请输入企业名称"
    },
    options: []
  },
  {
    field: "plate_number",
    label: "车牌号",
    type: "input",
    iw: 160,
    props: {
      placeholder: "请输入车牌号"
    }
  }
];
